/***** Default and helpers *****/

@import "mixins";
@import "variables";


// @font-face {
//     font-family: 'Garamond';
//     src: local('Garamond'),
//         url('../fonts/subset-Garamond.woff2') format('woff2'),
//         url('../fonts/subset-Garamond.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Nunito';
    src: local('Nunito Regular'), local('Nunito-Regular'),
        url('../fonts/subset-Nunito-Regular.woff2') format('woff2'),
        url('../fonts/subset-Nunito-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito Bold'), local('Nunito-Bold'),
        url('../fonts/subset-Nunito-Bold.woff2') format('woff2'),
        url('../fonts/subset-Nunito-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito Light'), local('Nunito-Light'),
        url('../fonts/subset-Nunito-Light.woff2') format('woff2'),
        url('../fonts/subset-Nunito-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
        url('../fonts/subset-Nunito-SemiBold.woff2') format('woff2'),
        url('../fonts/subset-Nunito-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: local('PT Serif Bold'), local('PTSerif-Bold'),
        url('../fonts/subset-PTSerif-Bold.woff2') format('woff2'),
        url('../fonts/subset-PTSerif-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: local('PT Serif'), local('PTSerif-Regular'),
        url('../fonts/subset-PTSerif-Regular.woff2') format('woff2'),
        url('../fonts/subset-PTSerif-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
  overflow: none;
  padding: 0;
  margin: 0;
  height: 100%;
  // scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  color: $black;
  font-family: $font-basic;
  font-size: 16px;
  height: 100%;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

input[type="submit"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="url"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

input,
textarea,
label {
  font-family: $font-basic;
}
a,
button,
input,
textarea,
select {
  outline: none;
}


input,
textarea {
  @include placeholder {
    opacity: 0.7;
  }
  &:focus {
    @include placeholder {
      opacity: 0.4;
    }
  }
}

table {
  border-collapse: collapse;
}

a {
  color: $black;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    opacity: .8;
  }
}

h1,
h2 {
  font-family: $font-heading;
  line-height: 1.2;
  font-weight: normal;
}

h1 {
  font-size: 48px;
  margin: 0;
  @include max($sm) {
    font-size: 36px;
  }
}
h2 {
  font-size: 36px;
  margin: 0;
  @include max($sm) {
    font-size: 30px;
  }
}
h3 {

}
h4 {
}

p {
  margin: 0 0 1em;
}

b,
strong {
}

img {
  max-width: 100%;
  height: auto;
}
