/***** Layout *****/

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  max-width: $huge;
  @include min($huge) {
    padding: 0 70px;
  }
  @include max($lg) {
    padding: 0 30px;
  }
  @include max($sm) {
    padding: 0 20px;
  }
  @include max($xxxs) {
    padding: 0 10px;
  }
}
.container-fluid {
  padding: 0 40px;
  @include max($lg) {
    padding: 0 30px;
  }
  @include max($sm) {
    padding: 0 20px;
  }
}