/***** Simple elements, atoms *****/

.hidden {
  display: none;
}

.visible {
  display: block;
}

.center {
  text-align: center;
}