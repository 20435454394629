/***** Homepage *****/

/*** Intro ***/

.section-intro {
  .intro-cols {
    display: flex;
    align-items: stretch;
    @include max($lg) {
      display: block;
    }
    .intro-col {
      &.intro-col-l {
        width: 50%;
        flex: 0 0 50%;
        line-height: 0;
        @include max($lg) {
          width: 100%;
          height: 600px;
        }
        @include max($sm) {
          height: 80vh;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &.intro-col-r {
        width: 50%;
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 110px 0;
        position: relative;
        @include max($lg) {
          width: 100%;
          padding: 70px 0 120px;
        }
        .intro-col-r-inner {
          max-width: 60%;
          padding-left: 5%;
          @include max($xxhuge) {
            max-width: 70%;
          }
          @include max($huge) {
            max-width: 80%;
          }
          @include max($lg) {
            padding-left: 0;
          }
        }
        .headline {
          margin-bottom: 30px;
          @include max($sm) {
            margin-bottom: 1em;
          }
        }
        .desc {
          margin-bottom: 40px;
        }
        .btn-block {}
      }
    }
  }

  .intro-bg {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    opacity: 0.5;
    z-index: -1;
  }
}

/*** Testimonials ***/

.section-testimonials {
  padding: 150px 0 170px;
  background: $greyf;
  position: relative;
  @include max($lg) {
    padding: 70px 0 120px;
  }
  .headline {
    margin-bottom: 70px;
  }
  .testimonials-videos {
    margin-bottom: 40px;
  }
  .videos-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -35px;
    @include max($lg) {
      margin: 0 -10px;
    }
    @include max($md) {
      margin: 0;
    }
    .item {
      width: 33.3333%;
      flex: 0 0 33.3333%;
      padding: 0 35px 50px;
      @include max($lg) {
        padding: 0 10px 40px;
      }
      @include max($md) {
        width: 100%;
        flex: 0 0 100%;
        padding: 0 0 30px;
      }
      .item-inner {
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          opacity: .8;
        }
      }
      .media {
        line-height: 0;
        img {
          width: 100%;
        }
      }
      .item-b {
        background: #fff;
        display: flex;
        align-items: center;
        padding: 8px 15px;
        .play {
          margin-right: 15px;
          line-height: 0;
          .icon {
            width: 40px;
            height: 40px;
          }
        }
        .desc {
          
          line-height: 1.2;
        }
      }
    }
  }
  .videos-more {
    max-height: 2000px;
    overflow: hidden;
    transition: all 1s ease-in-out;
    @include max($lg) {
      max-height: 6000px;
    }
    &.collapsed {
      max-height: 0;
    }
  }
  

  .testimonials-texts {
    margin: 0 -60px;
    @include max($lg) {
      margin: 0;
    }
  }
  .texts-list {
    display: flex;
    flex-wrap: wrap;
    @include max($md) {
      display: block;
    }
    .item {
      width: 50%;
      flex: 0 0 50%;
      padding: 60px 60px 40px 60px;
      position: relative;
      overflow: hidden;
      @include max($lg) {
        padding: 50px 20px 30px 20px;
      }
      @include max($md) {
        width: 100%;
      }
      .item-inner {
        padding: 0 30px 20px 0;
        border-width: 1px;
        border-style: solid;
        border-color: transparent #fff #fff transparent;
        position: relative;
        z-index: 1;
      }
      .desc {
        margin-bottom: 20px;
        font-size: 16px;
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .author {
        font-weight: 700;
        font-size: 16px;
      }
      &:before {
        content: "\201C\201D";
        display: block;
        position: absolute;
        font-size: 240px;
        line-height: 1;
        font-family: Georgia, serif;
        color: #fff;
        z-index: 0;
        top: 0;
        left: 0;
      }
    }
  }
  .texts-more {
    max-height: 1000px;
    overflow: hidden;
    transition: all 1s ease-in-out;
    @include max($lg) {
      max-height: 2000px;
    }
    &.collapsed {
      max-height: 0;
    }
  }

  .btn-block {
    max-height: 40px;
    overflow: hidden;
    opacity: 1;
    transition: all 0.5s;
    margin-top: 10px;
    .btn {}
    &.collapsed {
      max-height: 0;
      opacity: 0;
    }
  }

  .testimonials-bg {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    opacity: 0.8;
    z-index: 0;
  }
}

/*** Media ***/
.section-media {
  padding: 150px 0 170px;
  position: relative;
  @include max($lg) {
    padding: 70px 0 120px;
  }
  .media {
    position: relative;
    margin-bottom: 25px;
    .media-img {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      line-height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .icon {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -50px 0 0 -50px;
        fill: #fff;
        cursor: pointer;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        &:hover {
          fill: #eee;
        }
      }
      &.started {
        opacity: 1;
        visibility: visible;
        .icon {
          z-index: 1;
          &.icon-play {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      &.shown {
        opacity: 1;
        visibility: visible;
        .icon {
          &.icon-replay {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      // &.paused {
      //   opacity: 1;
      //   visibility: visible;
      //   &:after {
      //     content: "";
      //     display: block;
      //     position: absolute;
      //     top: 0;
      //     bottom: 0;
      //     left: 0;
      //     right: 0;
      //     background: rgba(0, 0, 0, .5);
      //     z-index: 0;
      //   }
      //   .icon {
      //     z-index: 1;
      //     &.icon-play {
      //       opacity: 1;
      //       visibility: visible;
      //     }
      //   }
      // }
    }
  }
  #mediaContainer {
    position: relative;

    &:after {
      content: "";
      display: block;
      padding-bottom: 56.25%;
    }
    // &.shown {
    //   &:before {
    //     content:"";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     right: 0;
    //     z-index: 1;
    //     cursor: pointer;
    //     background-color: black;
    //     background-repeat: no-repeat;
    //     background-position: center; 
    //     background-size: 64px 64px;
    //     background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCI+PHBhdGggZD0iTTI1NSAxMDJWMEwxMjcuNSAxMjcuNSAyNTUgMjU1VjE1M2M4NC4xNSAwIDE1MyA2OC44NSAxNTMgMTUzcy02OC44NSAxNTMtMTUzIDE1My0xNTMtNjguODUtMTUzLTE1M0g1MWMwIDExMi4yIDkxLjggMjA0IDIwNCAyMDRzMjA0LTkxLjggMjA0LTIwNC05MS44LTIwNC0yMDQtMjA0eiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==);
    //   }
    // }

    #player {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    img {}
    .icon {}
  }
  .desc {
    text-align: center;
    margin: 0 auto 25px;
    max-width: 800px;
  }
  .btn-block {
    margin-bottom: 25px;
  }

  .media-bg {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    opacity: 0.5;
    z-index: -1;
  }

  .survey {
    padding-top: 20px;
  }
}


/*** Work ***/

.section-work {
  padding: 110px 0 170px;
  background: $greyf;
  position: relative;
  @include max($lg) {
    padding: 70px 0 120px;
  }
  .headline {}

  .work-cols {
    display: flex;
    margin-bottom: 20px;
    @include max($lg) {
      display: block;
    }
    .work-col {
      width: 50%;
      flex: 0 0 50%;
      @include max($lg) {
        width: 100%;
      }
      &.work-col-l {
        padding-right: 40px;
        @include max($lg) {
          padding: 0 0 20px;
        }
        .work-questions {

        }
      }
      &.work-col-r {
        img {}
      }
    }
  }
  .work-lead {
    border: 1px solid #fff;
    padding: 30px 40px;
    margin: 40px 0;
    font-family: $font-heading;
    font-size: 36px;
    text-align: center;
    line-height: 1.2;
    @include max($sm) {
      font-size: 24px; 
    }
    p {
      margin: 0 auto;
      max-width: 800px;
    }
  }

  .work-desc {
    margin: 0 -40px 40px;
    display: flex;
    @include max($xxxl) {
      display: block;
      margin: 0 0 40px;
    }
    .work-col {
      padding: 0 40px;
      @include max($xxxl) {
        padding: 0;
        margin-bottom: 40px;
        width: 100% !important;
      }
      &:nth-child(1) {
        width: 42%;
        flex: 0 0 42%;
      }
      &:nth-child(2) {
        width: 29%;
        flex: 0 0 29%;
      }
      &:nth-child(3) {
        width: 29%;
        flex: 0 0 29%;
      }
    }
    h3 {
      font-weight: normal;
      font-size: 16px;
      text-transform: uppercase;
      // text-decoration: underline;
      margin: 0 0 20px;
    }
  }
  .btn-block {}


  .work-bg {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    opacity: 0.8;
    z-index: 0;
  }
  
}

/*** About ***/

.section-about {

  .about-cols {
    position: relative;
    display: flex;
    @include max($md) {
      display: block;
    }
    .about-col {
      &.about-col-l {
        width: 60%;
        flex: 0 0 60%;
        display: flex;
        align-items: center;
        padding: 110px 0 170px;
        @include max($md) {
          width: 100%;
          padding: 70px 0 0px;
        }
        .about-col-inner {          
          max-width: 80%;
          margin: 0 auto;
          @include max($huge) {
            padding-left: 0;
            max-width: 100%;
          }
        }
        .headline {}
        .desc {
        }
        .about-bg {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          opacity: 0.5;
          z-index: -1;
        }
      }
      &.about-col-r {
        width: 40%;
        flex: 0 0 40%;
        position: relative;
        @include max($md) {
          width: 300px;
          margin: 20px auto 70px;
          height: auto;
        }
        @include max($xs) {
          width: 100%;
          margin: 20px 0 0;
        }
        &:before {
          content: "";
          display: block;
          padding-bottom: 150%;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        
      }
    }
  }
}

/*** Next ***/

.section-next {
  position: relative;
  padding: 180px 0 230px;
  background: url('../images/next.jpg') 50% 50% no-repeat;
  background-size: cover;
  color: #fff; 
  @include max($lg) {
    padding: 70px 0 120px;
  }
  .headline {
    margin-bottom: 80px;
    @include max($sm) {
      margin-bottom: 2em;
    }
    h2 {
      &:after {
        background: #fff;
      }
    }
  }
  .container {
    max-width: $lg;
  }
  .desc {
    ol {
      counter-reset: olcounter;
      list-style: none;
      padding: 0;
      margin: 0 0 80px;
      @include max($lg) {
        margin-bottom: 40px;
      }
      li {
        counter-increment: olcounter;
        position: relative;
        padding-left: 40px;
        margin: 40px 0;
        font-size: 24px;
        line-height: 1.2;
        @include max($sm) {
          font-size: 18px;
          margin: 1em 0;
        }
        &:before {
          content: counter(olcounter);
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 2px solid #fff;
          text-align: center;
          line-height: 26px;
          font-size: 20px;
          @include max($sm) {
            font-size: 18px;
            top: -5px;
          }
        }
      }
    }
  }
  .btn-block {
    .btn {
      
    }
  }

  .next-bg {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: 0;
  }
}

// body.magnificpopupnoscroll {
//   overflow-y: hidden !important;
// }