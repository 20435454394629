/* Survey Page */

.page-survey {
  
}

.section-survey {
  padding: 0 0 20px;
  position: relative;
  @include max($sm) {
    
  }
  .headline {
    padding: 10px 0;
    background: #0084ff;
    color: #fff;
    font-family: $font-basic;
    margin: 0;
    @include max($sm) {
      
    }
    
  }
  h1 {
    font-size: 26px;
    font-family: $font-basic;
    @include max($xxs) {
      font-size: 23px;
    }
    @include max($xxxs) {
      font-size: 20px;
    }
  }
  .survey {
    padding-top: 20px;
    @include max($sm) {
      padding: 0;
      margin-top: -30px;
    }
  }
}
