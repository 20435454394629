/***** Components, common blocks *****/

.headline {
  position: relative;
  margin-bottom: 50px;

  
  h2 {
    margin: 0;
    position: relative;
    text-transform: uppercase;
    padding-bottom: 10px;
    &:after {
      content: "";
      display: block;
      width: 70px;
      height: 1px;
      background: $black;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &.center {
    text-align: center;
    h2 {
      &:after {
        left: 50%;
        margin-left: -35px;
      }
    }
  }
}

.txt {
  p {
    &:not(:last-child) {
      margin: 0 0 1em;
    }
  }
  ul {
    margin: 1em 0;
    padding: 0 0 0 1em;
    li {
      margin: 0.7em 0;
      padding: 0;
    }
    &.list-arrow {
      padding: 0;
      li {
        position: relative;
        padding-left: 30px;
        display: block;
        background: url('../images/icons/arrow.svg') 0 3px no-repeat;
        background-size: 16px 16px;
      }
    }
    &.dash {
      padding: 0;
      li {
        padding: 0 0 0 20px;
        position: relative;
        list-style: none;
        &:before {
          content: "-";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  ol {
    
  }
  .table {
    tr {}
    td {}
  }
}


/*** Popups ***/

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-vertical {
  .mfp-iframe-scaler {
    padding-top: 177.7778%;
  }
  .mfp-iframe-holder {
    .mfp-content {
      width: 400px;
      @include min($xxhuge) {
        width: 500px;
      }
      @include max($sm) {
        width: 80%;
      }
    }
  }
}