/***** Header *****/

.header {
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.5s;
  
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*@include max($md) {
      display: block;
      text-align: center; 
    }*/
  }
  .logo {
    display: flex;
    align-items: center;
    @include max($lg) {
      z-index: 2;
      position: relative;
    }
    img {}
    span {
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 22px;
      letter-spacing: -0.04em;
      @include max($xxxs) {
        font-size: 18px;
      }

    }
  }
  .header-r {
    display: flex;
    align-items: center;
    margin-right: -10px;
    .header-phone {
      margin-right: 40px;
      @include max($md) {
        display: none;
      }
      a {
        display: block;
      }
    }
    .header-email {
      margin-right: 40px;
      @include max($md) {
        display: none;
      }
      a {
        display: block;
      }
    }
    .header-menu {
      position: relative;

      .header-menu-btn {
        padding: 10px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 0;
        cursor: pointer;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0);
        transition: all 0.3s;
        @include max($lg) {
          z-index: 2;
          position: relative;
        }
        .icon {
          width: 22px;
          transition: all 0.3s;
          &.icon-close {
            display: none;
          }
        }
        &:hover {
          .icon {
            fill: $grey6;
          }
        }
        &.expanded {

          @include max($lg) {
            // background: rgba(0, 0, 0, .1);
            .icon {
              &.icon-menu {
                display: none;
              }
              &.icon-close {
                display: block;
              }
            }
          }
        }
      }
      .header-menu-list {
        position: absolute;
        right: 0;
        top: 53px;
        max-height: 0;
        overflow: hidden;
        z-index: 1;
        transition: all 0.3s;
        @include max($lg) {
          position: fixed;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(255, 255, 255, .95);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.expanded {
          max-height: 500px;
          @include max($lg) {
            max-height: 100%;
          }
        }
        ul {
          margin: 0;
          padding: 0;
          width: 200px;
          box-shadow: 0 0 4px rgba(0, 0, 0, .1);
          background: rgba(255, 255, 255, .85);
          @include max($lg) {
            box-shadow: none;
            background: none;
          }
          li {
            list-style: none;
            margin: 0;
            padding: 0;
            display: block;
            a {
              display: block;
              padding: 10px 20px;
            }
            &:not(:last-child) {
              a {
                border-bottom: 1px solid #eee;
              }
            }
          }
        }
      }
    }
  }
}

body.scrolled {
  .header {
    background: rgba(255, 255, 255, .8);
    padding: 5px 0;
  }
}