/***** Buttons *****/

.btn {
  cursor: pointer;
  transition: all 0.2s;
  display: inline-block;
  text-align: center;
  font-family: $font-basic;
  padding: 10px 20px;
  &:hover {

  }
  &.btn-primary {
    border: 2px solid $black;
    height: 40px;
    padding: 8px 30px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    span {
      position: relative;
      z-index: 1;
    }
    &:after {      
      content: '';
      position: absolute;
      z-index: 0;
      transition: all 0.4s;
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      background: #000;
    }
    &:hover {
      opacity: 1;
      color: #fff;
      &:after {
        height: 100%;
      }

    }
  }
  &.btn-secondary {
    &:hover {
    }
  }
  &.btn-lg {
    height: 50px;
    font-size: 20px;
    padding: 10px 40px;
    border-width: 3px;
    line-height: 24px;
  }

  &.btn-sm {
    height: 26px;
    font-size: 12px;
    padding: 5px 20px;
    border-width: 1px;
    line-height: 14px;
  }
  &.btn-invert {
    background: none;
    color: #fff;
    border-color: #fff;
    &:after {
      background: #fff;
    }
    &:hover {
      color: #000;
    }
  }
}
.btn-block {
  display: flex;
  flex-wrap: wrap;
  .btn {
    margin: 0 8px 4px 0;
  }
  &.center {
    justify-content: center;
  }
}