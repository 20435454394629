/***** Form elements *****/

.form-group {
  margin-bottom: 20px;
}
.form-control {
  border: 1px solid $black;
  background: #fff;
  border-radius: 5px;
  display: block;
  width: 100%;
  padding: 10px 20px;
  line-height: 20px;
  font-size: 16px;
}