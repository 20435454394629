/* Color */
$black:  #000;
$grey3:  #333;
$grey6:  #666;
$grey9:  #999;
$greyb:  #bbb;
$greyd:  #ddd;
$greyf:  #f2f2f2;


$font-heading: 'PT Serif', serif;
$font-basic: 'Nunito', sans-serif;

/* Size */
$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
