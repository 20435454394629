/***** Footer *****/

.footer {
  padding: 70px 0;
  @include max($lg) {
    padding: 40px 0 20px;
  }
  .footer-cols {
    display: flex;
    margin: 0 -20px;
    @include max($lg) {
      flex-wrap: wrap;
    }
    @include max($md) {
      display: block;
      margin: 0;
    }
    .footer-col {
      flex: 0 0 33.3333%;
      width: 33.3333%;
      padding: 0 20px;
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 20px 20px;
      }
      @include max($md) {
        width: 100%;
        padding: 0 0 20px;
      }
      &.footer-col-1 {
        .logo {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          img {}
          span {
            margin-left: 10px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 22px;
            letter-spacing: -0.04em;
          }
        }
        .info-block {
          .info {
            .txt {
              margin-bottom: 10px;
            }
          }
        } 
      }
      &.footer-col-2 {
        padding-top: 20px;
        .info-block {
          margin-bottom: 15px;
        }
      }
      &.footer-col-3 {
        padding-top: 20px;
        @include max($lg) {
          width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
  .info-block {
    display: flex;
    position: relative;
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      &.icon-map {
        width: 16px;
        height: 21px;
      }
      &.icon-email {
        width: 16px;
        height: 12px;
      }
      &.icon-mobile {
        width: 11px;
        height: 19px;
      }
    }
    .info {
      padding-left: 30px;
    }
  }
  .socials {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .item {
      &:not(:last-child) {
        margin-right: 10px;
      }
      a {}
      .icon {
        width: 28px;
        height: 28px;
      }
    }
  }
  .copy {
    font-size: 16px;
    margin-bottom: 40px;
    text-align: right;
    @include max($lg) {
      text-align: left;
    }
  }
  .logos {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include max($lg) {
      justify-content: flex-start;
    }
    .item {
      &:not(:last-child) {
        margin-right: 15px;
      }
      img {}
    }
  }
}